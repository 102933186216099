import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

const Slideshow = ({ duration, transitionDuration, children }) => {
  const [position, setPosition] = React.useState(0);
  const slides = React.Children.toArray(children);

  React.useEffect(() => {
    const timer = setTimeout(() => setPosition((position + 1) % slides.length), duration * 1000);
    return () => clearTimeout(timer);
  }, [position, setPosition]);

  return (
    <div className="bg-black">
      {slides.map((slide, i) => (
        <motion.div
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          initial={{ opacity: 0 }}
          animate={{ opacity: i === position ? 1 : 0 }}
          transition={{ duration: transitionDuration }}
        >
          {slide}
        </motion.div>
      ))}
    </div>
  );
};

Slideshow.propTypes = {
  children: PropTypes.node.isRequired,
  duration: PropTypes.number,
  transitionDuration: PropTypes.number,
};

Slideshow.defaultProps = {
  duration: 8,
  transitionDuration: 1.2,
};

export default Slideshow;
