import React from 'react';
import useWindowSize from '@rooks/use-window-size';
import Head from 'next/head';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

import config from 'config';
import Link from 'components/Link';
import Slideshow from 'components/Slideshow';
import Logo from '../svg/logo.svg';
import { attributes } from '../../content/home.md';

const imageBase = 'https://res.cloudinary.com/globobeet/image/fetch';
const mobileTransforms = 'ar_3:4,q_auto,c_fill,g_faces:auto,f_auto';
const desktopTransforms = 'ar_4:3,q_auto,c_fill,g_faces:auto,f_auto';

export default () => {
  const { innerWidth } = useWindowSize();
  const mobile = innerWidth < 1024;

  return (
    <div className="font-display relative min-h-screen w-full flex flex-col justify-center py-12">
      <Head>
        <title key="title">{attributes.seoTitle}</title>
        <meta key="og-title" name="og:title" content={attributes.seoTitle} />
        <meta key="twitter-title" name="twitter:title" content={attributes.seoTitle} />
        <meta key="og-type" name="og:type" content="website" />
        <meta key="twitter-card" name="twitter:card" content="summary" />
        <meta key="og-url" name="og:url" content={config('/siteUrl')} />
        <meta key="twitter-url" name="twitter:url" content={config('/siteUrl')} />
        <meta name="description" content={attributes.seoDescription} />
        <meta key="og-description" name="og:description" content={attributes.seoDescription} />
        <meta
          key="twitter-description"
          name="twiter:description"
          content={attributes.seoDescription}
        />
        {/*
        <meta key="og-image" name="og:image" content={shareImage} />
        <meta key="twitter-image" name="twitter:image" content={shareImage} />
        */}
      </Head>
      <Slideshow>
        {attributes.images.map((image) => (
          <img
            key={image}
            className="absolute z-0 inset-0 w-full h-full object-cover object-center"
            src={`${imageBase}/${mobile ? mobileTransforms : desktopTransforms}/${config(
              '/siteUrl'
            )}${image}`}
            alt=""
          />
        ))}
      </Slideshow>
      <div className="absolute z-20 inset-0 bg-gray-900 opacity-75" />
      <main className="container relative z-30">
        <Logo className="block w-48 lg:w-full max-w-xs mx-auto sm:mx-0" />

        <div className="my-12 sm:my-16">
          <h1 className="text-4xl md:text-5xl leading-none uppercase text-white text-shadow-lg">
            {attributes.title}
          </h1>
          <p className="text-3xl md:text-4xl leading-tight uppercase text-gray-300 text-shadow-lg mt-2">
            {attributes.subtitle}
          </p>
        </div>

        <div className="text-2xl md:text-4xl uppercase flex flex-col sm:flex-row items-stretch sm:items-center -ml-8 -mt-4 text-center sm:text-left">
          <div className="pl-8 pt-4">
            <Link
              href={`mailto:${attributes.quoteRequestEmail}?subject=${attributes.quoteRequestSubject}`}
              className="block w-full sm:w-auto bg-brand py-3 px-3 sm:px-8 text-white hover:shadow-xl transition-all duration-200"
            >
              {attributes.quoteRequestLabel}
            </Link>
          </div>
          <div className="pl-8 pt-4">
            <p className="text-gray-300 text-shadow-lg">
              or call <span className="text-white">{attributes.phoneNumber}</span>
            </p>
          </div>
        </div>

        <div className="mt-12 sm:mt-24 text-center sm:text-left">
          <div className="h-px bg-gray-300 opacity-25 lg:w-2/3 mb-12 sm:mb-16" />
          <p className="white-space-pre-line uppercase text-white text-xl sm:text-2xl text-gray-300">
            {attributes.address}
          </p>
          <div className="flex justify-center sm:justify-start items-center mt-4 text-xl uppercase">
            <Link
              href={attributes.facebookUrl}
              className="flex items-center text-white hover:text-gray-300 transition-colors duration-200"
            >
              <FontAwesomeIcon icon={faFacebook} size="lg" />
            </Link>
            <Link
              href={attributes.instagramUrl}
              className="flex items-center text-white hover:text-gray-300 transition-colors duration-200 ml-4"
            >
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};
